<template>
  <section>
    <TitleBar>
      Assembl&eacute;e g&eacute;n&eacute;rale {{ $route.params.id }}
      <template #toolbar-content>
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(dateHeureDepot)="{ item }">
          <DateTimeComponent :value="item.dateHeureDepot"></DateTimeComponent>
        </template>
        <template #cell(commentaire)="{ item }">
          <span class="commentaire">{{ item.commentaire }}</span>
        </template>
        <template #cell(fichier)="{ item }">
          <DownloadComponent
            :typeDocument="typeDocument"
            :targetId="item.idReponse"
          ></DownloadComponent>
        </template>
        <template #pagination>
          <strong>{{ nombreReponses }}</strong> coupons-r&eacute;ponse -
          <strong>{{ nombrePouvoirs }}</strong> bons pour pouvoir
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import DateTimeComponent from "../../components/DateTimeComponent.vue";
import DownloadComponent from "../../components/controls/DownloadComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "GestionAssembleeGeneraleView",
  components: {
    TitleBar,
    TableButtonGroup,
    ErrorComponent,
    PaginatedTable,
    DateTimeComponent,
    DownloadComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.REPONSES_AG),
      typeDocument: this.$store.state.expl.typesDocument.REPONSE_AG,
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
    nombreReponses() {
      return (
        this.page?.content.filter((r) => r.typeDocument === "COUPON_REPONSE")
          .length ?? 0
      );
    },
    nombrePouvoirs() {
      return (
        this.page?.content.filter((r) => r.typeDocument === "BON_POUVOIR")
          .length ?? 0
      );
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    isoSqlDateToFrenchDate: UtilsService.isoSqlDateToFrenchDate,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let response = await ExploitationService.getReponsesAg(
          this.$route.params.id,
          this.getSearchPayload()
        );
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>
